class ProductMenu{
  #_settings = {
    elementMenu:'#incografMenu',
    elementBtn:'#inco-btn-menu',
    containerMenu:'#incografMenu',
    isAjaxChilds: false,
    isAjaxSearch: false,
    endpoints: {
      childs: null,
      search: null,
    },
    searchElement: null,
    startOpened: false,
    events: {
      beforeClickOpen: null,
      afterClickOpen: null,
      beforeClickChildOpen: null,
      afterClickChildRequest: null,
    }
  };

  #menuOpeningStatus = false;
  #elementSelector = null;
  #elementSelectorBtn = null;
  #elementSelectorContainer = null;

  constructor(settings = {}) {

    this.settings = {
      ...this.#_settings,
      ...settings,
    }
    this.#elementSelector = document.querySelectorAll(this.settings.elementMenu);
    this.#elementSelectorBtn = document.querySelectorAll(this.settings.elementBtn);
    this.#elementSelectorContainer = document.querySelectorAll(this.settings.containerMenu);
    this.#applySettings();
    this.bindEvents();
  }

  #applySettings() {
    if (this.#elementSelector == null) {
      return;
    }
    if (this.#elementSelector.length > 0) {
      this.#elementSelector = this.#elementSelector[0];
    }
    if (this.#elementSelectorBtn.length > 0) {
      this.#elementSelectorBtn = this.#elementSelectorBtn[0];
    }
    if (this.#elementSelectorContainer.length > 0) {
      this.#elementSelectorContainer = this.#elementSelectorContainer[0];
    }
    // start with menu open ou close
    if (this.settings.startOpened) {
      this.#menuOpeningStatus = true;

      this.#elementSelectorContainer.classList.remove('menu-hide');

      this.#elementSelectorContainer.classList.add('menu-show');

    }
  }

  bindEventsForChilds(element) {
   // console.log("ProductMenu:bindEventsForChilds Started");
    const self = this;

    for (const elementIncoChild of element.querySelectorAll('.inco-childs')) {
      elementIncoChild.addEventListener("click", function (e) {
        e.stopPropagation();

        const downEvent = new PointerEvent("pointerdown",
          {
            pointerId: 1,
            bubbles: true,
            cancelable: true,
            pointerType: "mouse",
            isPrimary: true
          });

        if (typeof self.settings.events.beforeClickChildOpen === "function") {
          self.settings.events.beforeClickChildOpen(downEvent);
          if (downEvent.cancelBubble) {
            return;
          }
        }

        const idParentCategory = elementIncoChild.dataset.id || 0;
        const child = document.querySelector(".sub-menu-list-" + idParentCategory);

        const arrow = child.previousElementSibling.children[0];

        if (child.classList.contains('hide')) {
          child.classList.remove('hide');
          child.classList.add('show');

          arrow.style.opacity = 1;

        } else {
          child.classList.remove('show');
          child.classList.add('hide');

          arrow.style.opacity = 0;
          return;
        }

        const xhr = new window.fetchdata();
        const fullUrl = `${baseUrl}/ajaxservice/get-sub-categories/${idParentCategory}`;
        xhr.fetchData(
          fullUrl,
          'GET',
          undefined,
          (result) => {

            if (typeof self.settings.events.afterClickChildRequest === "function") {
              self.settings.events.afterClickChildRequest(result, e);
              if (e.cancelBubble) {
                return;
              }
            }
            //Find the listview.

            child.innerHTML = result;
            self.bindEventsForChilds(child);
          }
        );
      });
    }
  }

  bindEvents() {
   // console.log("ProductMenu:bindEvents Started", this.settings);
    // onclick menu Open and close
    const self = this;
    //I'm using "click" but it works with any event
    document.addEventListener('click', function (event) {

      const isClickInsideElementSelector = self.#elementSelector.contains(event.target);
      const isClickInsideElementSelectorBtn = self.#elementSelectorBtn.contains(event.target);
      const isClickInsideElementSelectorContainer = self.#elementSelectorContainer.contains(event.target);

      if(isClickInsideElementSelectorBtn) {
        self.#menuOpeningStatus = !self.#menuOpeningStatus;
        if (self.#menuOpeningStatus) {
          self.#elementSelectorContainer.classList.remove('menu-hide');
          self.#elementSelectorContainer.classList.add('menu-show');

          self.#elementSelectorBtn.classList.remove('closed-menu');
          self.#elementSelectorBtn.classList.add('open-menu');
        } else {
          self.#elementSelectorContainer.classList.remove('menu-show');
          self.#elementSelectorContainer.classList.add('menu-hide');

          self.#elementSelectorBtn.classList.remove('open-menu');
          self.#elementSelectorBtn.classList.add('closed-menu');
        }
        return;
      }

      self.#menuOpeningStatus = !self.#menuOpeningStatus;
      if (typeof self.settings.events.beforeClickOpen === "function") {
        self.settings.events.beforeClickOpen(event);
        if (event.cancelBubble) {
          return;
        }
      }
      if (!(isClickInsideElementSelector || isClickInsideElementSelectorBtn || isClickInsideElementSelectorContainer)) {
        //the click was outside the specifiedElement, do something
//         console.log("the click was outside the specifiedElement, do something");
        // menu Open and menu Close

        self.#elementSelectorContainer.classList.remove('menu-show');
        self.#elementSelectorContainer.classList.add('menu-hide');
        self.#menuOpeningStatus = false;


        self.#elementSelectorBtn.classList.remove('open-menu');
        self.#elementSelectorBtn.classList.add('closed-menu');


      } else {
        self.#menuOpeningStatus = true;
        self.#elementSelectorContainer.classList.add('menu-show');
        self.#elementSelectorContainer.classList.remove('menu-hide');


        self.#elementSelectorBtn.classList.add('open-menu');
        self.#elementSelectorBtn.classList.remove('closed-menu');
        //self.clickOldOpen(event);
      }

      if (typeof self.settings.events.afterClickOpen === "function") {
        self.settings.events.afterClickOpen(event);
        if (event.cancelBubble) {
          return;
        }
      }

    });


    this.bindEventsForChilds(this.#elementSelector);


  }

  clickOldOpen(e) {
    const self = this;
    self.#menuOpeningStatus = !self.#menuOpeningStatus;
    if (typeof self.settings.events.beforeClickOpen === "function") {
      self.settings.events.beforeClickOpen(e);
      if (e.cancelBubble) {
        return;
      }
    }
    // menu Open and menu Close
    if (self.#menuOpeningStatus) {
      self.#elementSelectorContainer.classList.remove('menu-hide');
      self.#elementSelectorContainer.classList.add('menu-show');

      self.#elementSelectorBtn.classList.remove('closed-menu');
      self.#elementSelectorBtn.classList.add('open-menu');


    } else {
      self.#elementSelectorContainer.classList.remove('menu-show');
      self.#elementSelectorContainer.classList.add('menu-hide');

      self.#elementSelectorBtn.classList.remove('open-menu');
      self.#elementSelectorBtn.classList.add('closed-menu');
    }


    //
    if (typeof self.settings.events.afterClickOpen === "function") {
      self.settings.events.afterClickOpen(e);
      if (e.cancelBubble) {
        return;
      }
    }
  };

  destroy(){}
}

module.exports = ProductMenu;
